// Constant
import { CHAT_BOT } from "redux/constants";

export const chatClearAllMessages = (response) => ({
  type: CHAT_BOT.CHAT_CLEAR_ALL_MESSAGES,
  payload: {
    response: response
  }
});

export const chatAddMessage = (response) => ({
  type: CHAT_BOT.CHAT_ADD_MESSAGE,
  payload: {
    response: response
  }
});

export const chatSuccess = (response) => ({
  type: CHAT_BOT.CHAT_SUCCESS,
  payload: {
    response: response
  }
});

export const chatPending = () => ({
  type: CHAT_BOT.CHAT_PENDING
});
