import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from 'html-react-parser';
import { LoadingDotsFlashing } from "components/Loading/LoadingDotsFlashing";
import { selectors as chatbotSelector } from "../../redux/reducers/chatbot/chatbot_reducer";
import { removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { CHAT_BOT, ROLES } from "redux/constants";
import "./Chatbot.scss";


const Chatbot = () => {
  const dispatch = useDispatch();
  const isChatLoading = useSelector(chatbotSelector.getLoading);
  const idChatError = useSelector(chatbotSelector.getError)
  const errorMessage = useSelector(chatbotSelector.getErrorMessage)
  const messages = useSelector(chatbotSelector.getMessages)
  const chatContainerElementRef = useRef(null);


  const [isVisible, setIsVisible] = useState(false);
  const [input, setInput] = useState("");


  useEffect(() => {
    removeFromLocalStorage("chat_thread_id");
    dispatch({ type: CHAT_BOT.CHAT_CLEAR_ALL_MESSAGES, payload: {} });
  }, []);
  const toggleOverlay = () => {
    setIsVisible(!isVisible);
  };

  const handleSend = () => {
    if (!input.trim()) return;

    // Add user message
    const newMessage = { role: ROLES.USER, content: input };

    dispatch({ type: CHAT_BOT.CHAT_ADD_MESSAGE, payload: newMessage });
    handleScrollToActiveMessage();
    setInput("");

    dispatch({ type: CHAT_BOT.QUERY_CHAT_API, payload: { newMessage, dispatch, handleScrollToActiveMessage } });
  };

  const onEnterPress = (ev) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      handleSend();
    }
  };

  const handleScrollToActiveMessage = () => {
    setTimeout(() => {
      if (chatContainerElementRef.current) {
        const chatContainer = chatContainerElementRef.current;
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: "instant"
        })
      }
    }, 0);

  }

  return (
    <div>
      <button onClick={toggleOverlay} className="toggle-button">
        {isVisible ? "Chat" : "Chat"}
      </button>

      {isVisible && (
        <div className="chat-overlay">
          <div className="chat-header">
            <div><span>Chat</span></div>
            <div><button onClick={toggleOverlay} className="close-button">
              &times;
            </button>
            </div>
          </div>
          <div className="chat-body">
            <div className="chatMessageStream" ref={chatContainerElementRef} >
              {messages.map((answer, index) => (
                <div key={index}>
                  {answer.role === ROLES.USER &&
                    <div class="container__userChatMessage">
                      <div class="message">{answer.content}</div>
                    </div>
                  }
                  {answer.role === ROLES.ASSISTANT &&
                    <div class="chatMessageGpt">
                      <div class="answerContainer">
                        <div class="answerContainer__inner">
                          <div class="answerText">
                            {ReactHtmlParser(answer.content.replace(/\n/ig, '<br />').replace(/\*\*(.*?)\*\*/g, '<b>$1</b>'))}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              ))}
              {isChatLoading && (
                <>
                  <div className={"chatMessageGptMinWidth"}>
                    <LoadingDotsFlashing />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={onEnterPress}
              placeholder="Type your message..."
            />
            <button onClick={handleSend}>Send</button>
          </div>
        </div>
      )}
    </div >
  );
};

export default Chatbot;