import { CHAT_BOT } from "redux/constants";
// default name
export const name = 'chatbot';
// initial State 
export const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  messages: []
};

// selectors
export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getMessages: (state) => state[name].messages,
};

/**
 * @export
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case CHAT_BOT.CHAT_SUCCESS:
      // replace the last message
      // with the processed one
      const existingMessages = state.messages;
      //existingMessages.pop();
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        messages: [
          ...existingMessages,
          action.payload
        ]
      };
    case CHAT_BOT.CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message,
      };
    case CHAT_BOT.CHAT_ADD_MESSAGE:
      const msgs = state.messages;
      return {
        ...state,
        loading: false,
        messages: [...msgs, action.payload]
      };
    case CHAT_BOT.CHAT_CLEAR_ALL_MESSAGES:
      return {
        ...state,
        messages: []
      };
    case CHAT_BOT.CHAT_DELETE_MESSAGE:
      const _messages = state.messages;
      _messages.splice(action.payload.index, 1);
      return {
        ...state,
        messages: _messages
      };
    case CHAT_BOT.CHAT_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
      };
    default:
      return state;
  }
}

