import { put, call, takeEvery } from 'redux-saga/effects';
import CONFIG from "config";
import { sendToLocalStorage, getFromLocalStorageAsJSON, removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { moduleFailure } from "redux/global.action.js";
import { CHAT_BOT, ROLES } from "redux/constants";
import { AzureOpenAI } from "openai";

import {
  chatPending
} from "./chatbot.actions";

export function* queryChatAPI(action) {
  const { payload: {
    newMessage,
    dispatch,
    handleScrollToActiveMessage
  } } = action;
  yield put(chatPending());
  yield call(streamChat, newMessage, dispatch, handleScrollToActiveMessage);
}

const streamChat = async (newMessage, dispatch, handleScrollToActiveMessage) => {
  // Get environment variables
  const azureOpenAIKey = "9G7d5Z2nZFCdRRMHMeHUMwnGJmI11KJche05CGJoEEY1izMbrjKEJQQJ99ALACYeBjFXJ3w3AAABACOGUoxr";
  const azureOpenAIEndpoint = "https://bcg-ai-chopra-poc.openai.azure.com/";
  const azureOpenAIDeployment = "gpt-4o-mini";
  const azureOpenAIVersion = "2024-05-01-preview";

  // Check env variables
  if (!azureOpenAIKey || !azureOpenAIEndpoint || !azureOpenAIDeployment || !azureOpenAIVersion) {
    throw new Error(
      "Please set AZURE_OPENAI_KEY and AZURE_OPENAI_ENDPOINT and AZURE_OPENAI_DEPLOYMENT_NAME in your environment variables."
    );
  }

  // Get Azure SDK client
  const getClient = () => {
    const assistantsClient = new AzureOpenAI({
      endpoint: azureOpenAIEndpoint,
      apiVersion: azureOpenAIVersion,
      apiKey: azureOpenAIKey,
      dangerouslyAllowBrowser: true
    });
    return assistantsClient;
  };

  const assistantsClient = getClient();

  // const options = {
  //   model: azureOpenAIDeployment, // Deployment name seen in Azure AI Foundry portal
  //   name: "Math Tutor",
  //   instructions:
  //     "You are a personal math tutor. Write and run JavaScript code to answer math questions.",
  //   tools: [{ type: "code_interpreter" }],
  // };


  // // Create an assistant
  // const assistantResponse = await assistantsClient.beta.assistants.create(
  //   options
  // );
  //console.log(`Assistant created: ${JSON.stringify(assistantResponse)}`);


  const chatThreadId = getFromLocalStorageAsJSON("chat_thread_id");
  let assistantThread;
  if (!chatThreadId) {
    // Create a thread
    assistantThread = await assistantsClient.beta.threads.create({});
    console.log(`Thread created: ${JSON.stringify(assistantThread)}`);
    sendToLocalStorage("chat_thread_id", assistantThread.id);
  } else {
    assistantThread = { id: chatThreadId };
  }
  // Add a user question to the thread
  const threadResponse = await assistantsClient.beta.threads.messages.create(
    assistantThread.id,
    {
      role: newMessage.role,
      content: newMessage.content,
    }
  );
  console.log(`Message created:  ${JSON.stringify(threadResponse)}`);

  // Run the thread and poll it until it is in a terminal state
  const runResponse = await assistantsClient.beta.threads.runs.createAndPoll(
    assistantThread.id,
    {
      assistant_id: "asst_xzUgMB147LzKjG864iCZjNu1",
    },
    { pollIntervalMs: 500 }
  );
  console.log(`Run created:  ${JSON.stringify(runResponse)}`);

  // Get the messages
  const runMessages = await assistantsClient.beta.threads.messages.list(
    assistantThread.id
  );
  console.log({ runMessages })
  const assistantMessage = runMessages.data.filter(m => m.role === ROLES.ASSISTANT);
  console.log({ assistantMessage });
  for await (const runMessageDatum of runMessages) {
    for (const item of runMessageDatum.content) {
      // types are: "image_file" or "text"
      if (item.type === "text") {
        console.log(`Message content: ${JSON.stringify(item.text?.value)}`);
      }
    }
  }

  for (const item of assistantMessage[0].content) {
    // types are: "image_file" or "text"
    if (item.type === "text") {
      const newMessage = { role: ROLES.ASSISTANT, content: item.text?.value };
      dispatch({ type: CHAT_BOT.CHAT_ADD_MESSAGE, payload: newMessage });
      handleScrollToActiveMessage();
    }
  }
}


export default function* chatbotSaga() {
  try {
    yield takeEvery(CHAT_BOT.QUERY_CHAT_API, queryChatAPI);
  } catch (error) {
    yield put(moduleFailure(error, 'User Session Saga'));
  }
}