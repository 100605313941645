export const ALL_SERVICES = {
  GET_ALL_SERVICES: "GET_ALL_SERVICES",
  GET_ALL_SERVICES_PENDING: "GET_ALL_SERVICES_PENDING",
  GET_ALL_SERVICES_SUCCESS: "GET_ALL_SERVICES_SUCCESS",
  GET_ALL_SERVICES_FAILURE: "GET_ALL_SERVICES_FAILURE",
  GET_MFU: "GET_MFU",
  GET_MFU_PENDING: "GET_MFU_PENDING",
  GET_MFU_SUCCESS: "GET_MFU_SUCCESS",
  GET_MFU_FAILURE: "GET_MFU_FAILURE",
  GET_BANNERS: "GET_BANNERS",
  GET_BANNERS_PENDING: "GET_BANNERS_PENDING",
  GET_BANNERS_SUCCESS: "GET_BANNERS_SUCCESS",
  GET_BANNERS_FAILURE: "GET_BANNERS_FAILURE",
  GET_HELPFUL_RESOURCES: "GET_HELPFUL_RESOURCES",
  GET_HELPFUL_RESOURCES_PENDING: "GET_HELPFUL_RESOURCES_PENDING",
  GET_HELPFUL_RESOURCES_SUCCESS: "GET_HELPFUL_RESOURCES_SUCCESS",
  GET_HELPFUL_RESOURCES_FAILURE: "GET_HELPFUL_RESOURCES_FAILURE",
  GET_ACTIVE_SERVICES_COUNT: "GET_ACTIVE_SERVICES_COUNT",
  GET_ACTIVE_SERVICES_COUNT_PENDING: "GET_ACTIVE_SERVICES_COUNT_PENDING",
  GET_ACTIVE_SERVICES_COUNT_SUCCESS: "GET_ACTIVE_SERVICES_COUNT_SUCCESS",
  GET_ACTIVE_SERVICES_COUNT_FAILURE: "GET_ACTIVE_SERVICES_COUNT_FAILURE",
};

export const CHAT_BOT = {
  QUERY_CHAT_API: "QUERY_CHAT_API",
  CHAT_PENDING: 'CHAT_PENDING',
  CHAT_SUCCESS: 'CHAT_SUCCESS',
  CHAT_FAILURE: 'CHAT_FAILURE',
  CHAT_CLEAR_ALL_MESSAGES: 'CHAT_CLEAR_ALL_MESSAGES',
  RESET_CHAT_STATE: 'RESET_CHAT_STATE',
  SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS: 'SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS',
  CHAT_STREAM_CHUNK: 'CHAT_STREAM_CHUNK',
  CHAT_STREAM_NEW_MESSAGE: 'CHAT_STREAM_NEW_MESSAGE',
  CHAT_ADD_MESSAGE: 'CHAT_ADD_MESSAGE',
  CHAT_DELETE_MESSAGE: 'CHAT_DELETE_MESSAGE',
}

export const ROLES = {
  USER: 'user',
  ASSISTANT: 'assistant',
  STATUS: 'status'
};

export const SN_DASHBOARD = {
  GET_SN_DASHBOARD_DATA: "GET_SN_DASHBOARD_DATA",
  SN_DASHBOARD_API_PENDING: 'SN_DASHBOARD_API_PENDING',
  SN_DASHBOARD_API_SUCCESS: 'SN_DASHBOARD_API_SUCCESS',
  SN_DASHBOARD_API_FAILURE: 'SN_DASHBOARD_API_FAILURE',
  SN_DASHBOARD_API_TAKING_LONG: 'SN_DASHBOARD_API_TAKING_LONG',
  SET_SN_DASHBOARD_DATA: 'SET_SN_DASHBOARD_DATA',
  SET_SN_DASHBOARD_STATUS_LIST: 'SET_SN_DASHBOARD_STATUS_LIST',

  PEOPLE_API_DATA_PENDING: 'PEOPLE_API_DATA_PENDING',
  PEOPLE_API_DATA_SUCCESS: 'PEOPLE_API_DATA_SUCCESS',
  PEOPLE_API_DATA_FAILURE: 'PEOPLE_API_DATA_FAILURE',
  PEOPLE_API_DATA_RESET: 'PEOPLE_API_DATA_RESET',

  PEOPLE_IMAGE_PENDING: 'PEOPLE_IMAGE_PENDING',
  PEOPLE_IMAGE_SUCCESS: 'PEOPLE_IMAGE_SUCCESS',
  PEOPLE_IMAGE_FAILURE: 'PEOPLE_IMAGE_FAILURE',
  PEOPLE_DATA_PENDING: 'PEOPLE_DATA_PENDING',
  PEOPLE_DATA_SUCCESS: 'PEOPLE_DATA_SUCCESS',
  PEOPLE_DATA_FAILURE: 'PEOPLE_DATA_FAILURE',
};

// Notification toastify
export const ERROR = "ERROR";
export const INFO = "INFO";
export const SUCCESS = "SUCCESS";
export const WARNING = "WARNING";
export const DISMISS_NOTIFICATIONS = "DISMISS_NOTIFICATIONS";


// Enitilement Actions
export const ENTITLEMENTS = {
  GET_ENTITLEMENTS: "GET_ENTITLEMENTS",
  API_GET_ENTITLEMENTS_PENDING: "API_GET_ENTITLEMENTS_PENDING",
  API_GET_ENTITLEMENTS_SUCCESS: "API_GET_ENTITLEMENTS_SUCCESS",
  API_GET_ENTITLEMENTS_FAILURE: "API_GET_ENTITLEMENTS_FAILURE",

  // Actions for checking whether the user is site admin/editor or not
  CHECK_SITE_ADMIN: "CHECK_SITE_ADMIN",
  API_CHECK_SITE_ADMIN_PENDING: "API_CHECK_SITE_ADMIN_PENDING",
  API_CHECK_SITE_ADMIN_SUCCESS: "API_CHECK_SITE_ADMIN_SUCCESS",
  API_CHECK_SITE_ADMIN_FAILURE: "API_CHECK_SITE_ADMIN_FAILURE",
}


// Global Actions
export const GLOBAL = {
  SHOW_PAGELOADER: "SHOW_PAGELOADER",
  HIDE_PAGELOADER: "HIDE_PAGELOADER",
  SHOW_FULLPAGELOADER: "SHOW_FULLPAGELOADER",
  HIDE_FULLPAGELOADER: "HIDE_FULLPAGELOADER",
  FULLPAGELOADER_ERROR: "FULLPAGELOADER_ERROR",
  DISABLE_BODY_OVERFLOW: "DISABLE_BODY_OVERFLOW",
  ENABLE_BODY_OVERFLOW: "ENABLE_BODY_OVERFLOW",
  LOGGEDIN_USER_DATA: "LOGGEDIN_USER_DATA"
};

export const PEOPLE_DATA = {
  API_PEOPLE_RESULTS_PENDING: "API_PEOPLE_RESULTS_PENDING",
  API_PEOPLE_RESULTS_SUCCESS: "API_PEOPLE_RESULTS_SUCCESS",
  API_PEOPLE_RESULTS_FAILURE: "API_PEOPLE_RESULTS_FAILURE",
  API_TYPEAHEAD_DATA_SUCCESS: "API_TYPEAHEAD_DATA_SUCCESS"
};

export const CCO_DATA = {
  GET_CCO_RESULTS: "GET_CCO_RESULTS",
  GET_CCO_RESULTS_PENDING: "GET_CCO_RESULTS_PENDING",
  GET_CCO_RESULTS_SUCCESS: "GET_CCO_RESULTS_SUCCESS",
  GET_CCO_RESULTS_FAILURE: "GET_CCO_RESULTS_FAILURE",
};


export const USER_SESSION = {
  GET_ACTIVE_USER_SESSION: "GET_ACTIVE_USER_SESSION",
  ACTIVE_USER_SESSION_PENDING: "GET_ACTIVE_USER_SESSION_PENDING",
  ACTIVE_USER_SESSION_SUCCESS: "GET_ACTIVE_USER_SESSION_SUCCESS",
  ACTIVE_USER_SESSION_FAILURE: "GET_ACTIVE_USER_SESSION_FAILURE",
};

// Authorization Actions
export const AUTH = {
  GET_SMARTLOGIC_BEARER_TOKEN: "GET_SMARTLOGIC_BEARER_TOKEN",
  GET_SMARTLOGIC_BEARER_TOKEN_PENDING: "GET_SMARTLOGIC_BEARER_TOKEN_PENDING",
  GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS: "GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS",
  GET_SMARTLOGIC_BEARER_TOKEN_FAILURE: "GET_SMARTLOGIC_BEARER_TOKEN_FAILURE"
};